export enum MatchingResultType {
  Perfect = 1,
  Match = 4,
  Probable = 2,
  Lacked
}

export enum SearchMatchingPercent {
  Perfect = 80,
  Probable = 60,
}

export interface SearchMatchingDto {
  readonly label: MatchingResultType;
  readonly department: SearchMatchingItemDto;
  readonly position: SearchMatchingItemDto;
  readonly salary: SearchMatchingSalaryDto | null;
  readonly dateOfJoin: SearchMatchingDateDto;
  readonly experience: SearchMatchingExperienceDto;
  readonly skills: SearchMatchingItemDto[];
  readonly boatTypes: SearchMatchingItemDto[];
}

export interface SearchMatchingDateDto {
  readonly startDate: string;
  readonly endDate: string;
  readonly match: boolean;
}

export interface SearchMatchingExperienceDto {
  readonly offer: number;
  readonly user: number;
  readonly percent: number;
  readonly match: boolean;
}

export interface SearchMatchingSalaryDto {
  readonly currency: string;
  readonly offer: number;
  readonly user: number;
  readonly percent: number;
  readonly match: boolean;
  readonly negotiable: boolean;
}

export interface SearchMatchingItemDto {
  readonly name: string;
  readonly match: boolean;
}

export class SearchMatchingLabel {
  constructor(
    public readonly type: MatchingResultType,
    public readonly label: string,
    public readonly additionalClass: string,
  ) {
  }
}
