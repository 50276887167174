import {LocationAutocompleteFormI} from '@controls/location-autocomplete';

import {SeazoneIcon} from '../../modules/seazone-icons';
import {SearchRegionItemDto} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {LocationDto, LocationLatLngDto, SearchLocationDirectories} from '../location';

export class SearchMapItemDto {
  constructor(
    public readonly id: number,
    public readonly image: string,
    public readonly location: LocationLatLngDto,
  ) {
  }
}

export class SearchChipsItemDto<T = any> {
  constructor(
    public readonly value: T | null,
    public readonly label: string,
    public readonly icon: SeazoneIcon | null = null,
    public readonly displayValue: T | string | null = null,
  ) {
  }
}

export class SearchChipsIconDto {
  constructor(
    public readonly id: number,
    public readonly icon: SeazoneIcon,
  ) {
  }
}

export class BoatSearchLocationForm {
  constructor(
    public readonly position: LocationDto | LocationAutocompleteFormI | null = null,
    public readonly searchRegion: number | null = null,
    public readonly distance: number | null = null,
    public readonly nearBoat: boolean | null = null,
    public readonly nearMe: boolean | null = null,
  ) {
  }
}

export class BoatSearchLocationParams {
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly address: string | null = null;
  readonly distance: number | null = null;
  readonly regions: number[] | null = null;
  readonly nearBoat: ConditionalStatus | null = null;
  readonly nearMe: ConditionalStatus | null = null;

  constructor(
    form: BoatSearchLocationForm,
  ) {
    const {position, nearBoat, nearMe, distance, searchRegion} = form;
    this.nearBoat = nearBoat ? booleanToConditionalStatus(nearBoat) : null;
    this.nearMe = nearMe ? booleanToConditionalStatus(nearMe) : null;
    this.distance = distance;
    this.regions = searchRegion ? [searchRegion] : null;
    if (position) {
      const {lat, lng, address} = position;
      this.myLocationLat = lat;
      this.myLocationLng = lng;
      this.address = address;
    }
  }
}

export class BoatSearchLocationDirectories {
  constructor(
    public readonly regionsChips: SearchRegionItemDto[],
    public readonly location: SearchLocationDirectories | null,
    public readonly distanceChips: SearchChipsItemDto[],
    public readonly nearBoatChips: SearchChipsItemDto | null,
    public readonly nearMeChips: SearchChipsItemDto | null,
  ) {
  }
}

export class SupplierSearchAutocompleteItemDto {
  constructor(
    public readonly type: string,
    public readonly id: number,
    public readonly name: string,
    public readonly group?: string,
  ) {
  }
}
